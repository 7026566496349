<template>
	<w-dialog
		max-width="500px"
		:title="$t('application.removal_dialog.title', { application: application && application.title ? application.title : null })"
		:value="dialog"
		@close="closeDialog()"
	>
		<v-layout column>
			<v-flex v-t="'application.removal_dialog.text_one'" my-1 shrink />
			<v-flex v-t="'application.removal_dialog.text_two'" my-1 shrink />
			<v-flex v-t="'application.removal_dialog.text_three'" my-1 shrink />
			<v-flex v-t="'application.removal_dialog.text_four'" mb-1 mt-3 shrink />
		</v-layout>
		<template v-slot:actions>
			<v-spacer />
			<w-btn :loading="loading" @click="removeApplication()">{{ $t('actions.remove') }}</w-btn>
		</template>
	</w-dialog>
</template>

<script>
export default {
	name: 'ApplicationRemovalDialog',
	props: {
		application: {
			default: null,
			required: false,
			type: Object
		},
		apiCall: {
			required: true,
			type: Function
		},
		value: {
			required: true,
			type: Boolean
		}
	},
	data: function () {
		return {
			loading: false
		}
	},
	computed: {
		dialog: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {
		closeDialog: function () {
			this.dialog = false
		},
		removeApplication: function () {
			this.loading = true
			this.apiCall()
				.then(() => {
					this.closeDialog()
				})
				.finally(() => {
					this.loading = false
				})
		}
	}
}
</script>